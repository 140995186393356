import { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { spacing } from 'theme';
import switchOffDisabledSvg from './switch-off-disabled.svg';
import switchOffSvg from './switch-off.svg';
import switchOnDisabledSvg from './switch-on-disabled.svg';
import switchOnSvg from './switch-on.svg';
const SwitchBase = styled.label<{
  'data-checked': boolean;
  'data-disabled'?: boolean;
}>({
  display: 'block',
  height: spacing.xl,
  width: spacing.xxl,
  cursor: 'pointer',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url("${switchOffSvg}")`,
  '&[data-disabled="true"]': {
    cursor: 'not-allowed',
    backgroundImage: `url("${switchOffDisabledSvg}")`
  },
  '&[data-checked="true"]': {
    backgroundImage: `url("${switchOnSvg}")`,
    '&[data-disabled="true"]': {
      backgroundImage: `url("${switchOnDisabledSvg}")`
    }
  }
});
const HiddenCheckbox = styled.input({
  zIndex: -1,
  position: 'absolute',
  opacity: 0
});
type AllowlistedHTMLInputProps = Pick<InputHTMLAttributes<HTMLInputElement>, 'id' | 'name'>;
interface ISwitch extends AllowlistedHTMLInputProps {
  checked: boolean;
  onCheckedChange?: (nextChecked: boolean) => void;
  disabled?: boolean;
  cyElementType?: string;
}

/**
 * Primitive UI component for displaying toggle state in form of a switch within the application.
 *
 * [Figma definition](https://www.figma.com/design/2fEScGXkSAxlZaLBCm4ki2/%F0%9F%92%A0-Official-Design-Library?node-id=866-55719&m=dev)
 *
 * @example
 *
 * ```tsx
 * <Switch checked={someState} onCheckedChange={nextState => setSomeState(nextState)} disabled={isControlDisabled} />
 * ```
 */
export default function Switch({
  checked,
  onCheckedChange,
  disabled,
  cyElementType,
  ...props
}: ISwitch) {
  return <SwitchBase data-checked={checked} data-disabled={disabled} data-cy-element-type={cyElementType}>
      <HiddenCheckbox type="checkbox" checked={checked} onChange={() => onCheckedChange?.(!checked)} disabled={disabled} {...props} />
    </SwitchBase>;
}