// Front-end flag keys
// Note: Back end registry is at feature-switch-registry.ts in the API package.

export enum EFeatureFlag {
  ExperimentDetailExtendedPrecisionKey = 'experiment-detail-extended-precision',
  UseUtcKey = 'use-utc',
  FeatureFlagAuditLog = 'feature-flag-audit-log',
  ExperimentMetricDisplayAbsoluteLift = 'experiment-metric-display-absolute-lift',
  ManualRefresh = 'manual-refresh',
  BayesianDetailsLossForControl = 'loss-for-control-in-bayesian-details',
  DefinitionsSkipRun = 'definitions-skip-run',
  UnlimitedEnvironments = 'unlimited-environments',
  FeatureFlagNotification = 'feature-flag-notification',
  HoldoutsAnalysisOnly = 'holdouts-analysis-only',
  HoldoutsEndToEnd = 'holdouts-end-to-end',
  KillAllQueriesButton = 'kill-all-queries-button',
  UserTeams = 'user-teams',
  TeamExperimentPermissions = 'team-experiment-permissions-1',
  TeamMetricPermissions = 'team-metric-permissions',
  MetricSync = 'metric-sync',
  WorkflowSettingsPage = 'workflow-settings-page',
  DisableNotifications = 'disable-notifications',
  EmailNotifications = 'email-notifications',
  FeatureFlagApproval = 'feature-flag-approval',
  CountDistinctAgg = 'count-distinct-agg',
  ExperimentRealTimeMetricsUi = 'experiment-live-metrics-ui',
  ExperimentRealTimeMetricsManualRecompute = 'experiment-live-metrics-manual-recompute',
  ExperimentDimensionalAnalysis = 'property-analysis-tab',
  FeatureFlagSemVer = 'feature-flag-semver-support',
  ShowTeamsPickerInterstitial = 'show-teams-picker-interstitial',
  FeatureFlagTeamPermissions = 'feature-flag-team-permissions',
  KnowledgeBase = 'knowledge-base',
  Layers = 'layers',
  InviteUser = 'invite-user',
  MultipleExperimentAllocation = 'multiple-experiment-allocation',
  BanditsUI = 'bandits-ui',
  SourceDiagnostics = 'source-diagnostics',
  SearchBarV2 = 'search-bar-v2',
  BigQueryOAuth = 'big-query-oauth',
  FFAudiences = 'ff-audiences',
  AdminMetricExportLog = 'admin-metric-export-log',
  PipelineMetricFilterDimensions = 'pipeline-metric-filter-dimensions',
  DatePartitionKeyUX = 'date-partition-key-ux',
  WebhooksAdminTab = 'webhooks-admin-tab',
  PipelineVisibilityWarehouseLeftNav = 'pipeline-visibility-warehouse-left-nav',
  FactLaggingTable = 'fact-lagging-table',
  MetricWindows = 'metric-windows',
  TeamScorecards = 'team-scorecards',
  TeamScorecardsQualityCard = 'team-scorecards-quality-card',
  FFUXImprovements = 'ff-ux-improvements',
  ClusteredAnalysis = 'clustered-analysis',
  FeatureFlagTrials = 'feature-flag-trials',
  SecondaryId = 'secondary-id',
  FFDetailsPageEnvironmentsSidebar = 'ff-details-page-environments-sidebar',
  NonInferiorityMetrics = 'non-inferiority-metrics',
  FeatureFlagScheduledRollouts = 'feature-flag-scheduled-rollouts',
  LastValueAggregation = 'last-value-aggregation',
  NoKnowledgeBaseExplores = 'no-knowledge-base-explores',
  FFUpdatedCreatePage = 'feature-flag-updated-create-page',
}
